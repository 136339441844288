.icon {
  display: inline-block;

  &--loading {
    width: 32px;
    height: 32px;
  }
  svg {
    height: 1em;
  }
}