.search-form {
  $this : &;
  position: relative;
  display: flex;
  align-items: center;
  &__button {
    position: absolute;
    border:0;
    outline: none;
    appearance: none;
    background: none;
    svg {
      width: 24px;
      height: 24px;
      fill: $white;
    }
  }
  &__input {
    font-size: 50px;
    font-weight: bold;
    background: none;
    border: 0;
    border-bottom: 4px solid $white;
    width: 100%;
    padding-left: 40px;
    color: $white;
    &::placeholder {
      color: rgba($white, .25);
    }
    &:focus {
      outline: none;
    }
  }   
}
