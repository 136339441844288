@font-face {
  font-family:'HelveticaNeueLT Std';
  src: url('fonts/HelveticaNeueLTStd-Roman.eot');
  src: url('fonts/HelveticaNeueLTStd-Roman.eot?#iefix') format('embedded-opentype'),
       url('fonts/HelveticaNeueLTStd-Roman.woff2') format('woff2'),
       url('fonts/HelveticaNeueLTStd-Roman.woff') format('woff'),
       url('fonts/HelveticaNeueLTStd-Roman.svg#HelveticaNeueLTStd-Roman') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'HelveticaNeueLT Std';
  src: url('fonts/HelveticaNeueLTStd-Md.eot');
  src: url('fonts/HelveticaNeueLTStd-Md.eot?#iefix') format('embedded-opentype'),
       url('fonts/HelveticaNeueLTStd-Md.woff2') format('woff2'),
       url('fonts/HelveticaNeueLTStd-Md.woff') format('woff'),
       url('fonts/HelveticaNeueLTStd-Md.svg#HelveticaNeueLTStd-Md"') format('svg');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'HelveticaNeueLT Std';
  src: url('fonts/HelveticaNeueLTStd-Bd.eot');
  src: url('fonts/HelveticaNeueLTStd-Bd.eot?#iefix') format('embedded-opentype'),
       url('fonts/HelveticaNeueLTStd-Bd.woff2') format('woff2'),
       url('fonts/HelveticaNeueLTStd-Bd.woff') format('woff'),
       url('fonts/HelveticaNeueLTStd-Bd.svg#HelveticaNeueLTStd-Bd"') format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

body {
  font-size: 16px;
  line-height: 1.375;
  letter-spacing: 0.0075em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

h1,h2,h3,h4,h5,h6 {
  margin-top: 0;
}

p {
  margin: 0;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: $gray-dark;
  transition: all .4s ease-in-out;
  text-decoration: none;
  &:hover {
    color: $green;
  }
}
@mixin body-copy {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.375;
  letter-spacing: 0.0125em;
}

@mixin large-copy {
  font-size: 20px;
  line-height: 1.35;
  font-weight: 500;
}

@mixin label {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.007em;
  line-height: 1;
}
@mixin headline_1() {
  font-size: 50px;
  font-weight: bold;
  letter-spacing: -0.036em;
  line-height: 1.04;
  color: $white;
  margin-bottom: 20px;
  @include r(medium) {
    font-size: 90px;
    line-height: 0.94;
  }
}
.headline_1 {
  @include headline_1;
}

@mixin subheadline_1() {
  font-size: 27px;
  font-weight: bold;
  letter-spacing: -0.023em;
  line-height: 1.18;
  margin-bottom: 10px;
}
.subheadline_1 {
  @include subheadline_1;
}

@mixin subheadline_2() {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.023em;
  line-height: 1.18;
  margin-bottom: 10px;
}
.subheadline_2 {
  @include subheadline_2;
}

@mixin subheadline_3() {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.0075em;
  line-height: 1.35;
  margin-bottom: 0;
}
.subheadline_3 {
  @include subheadline_3;
}

@mixin subheadline_4() {
  font-size: 20px;
  letter-spacing: 0.0075em;
  line-height: 1.18;
  margin-bottom: 15px;
}
.subheadline_4 {
  @include subheadline_4;
}

@mixin subheadline_5() {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  margin-bottom: 20px;
}
.subheadline_5 {
  @include subheadline_5;
}

@mixin link_1() {
  font-size: 42px;
  font-weight: bold;
  letter-spacing: -0.003em;
  line-height: 1.19;
}
.link_1 {
  @include link_1;
}

@mixin link_2() {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.023em;
  line-height: 1.25;
  margin-bottom: 15px;
  text-decoration: none;
}
@mixin link_5() {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.008em;
  line-height: 1.25;
  text-decoration: none;
}


@mixin underline {
  will-change: transform;
  text-decoration: none;
  background-image: linear-gradient(to bottom, $green 1px, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 0) 0);
  background-position: 0 calc(1em + 1px);
  display: inline;
  
  &:hover, &:focus {
  }
}
.underline {
  @include underline;
}
.output-text {
  a {
    @include underline;
  }
  ul {
    list-style-type: disc;
    margin-bottom: 20px;
  }
  ol {
    list-style-type: decimal;
    margin-bottom: 20px;
  }
  
  li {
    margin-left: 1.1em;
  }
}
