//Colors
$white        : #FFFFFF;
$gray-lighter : #E0E0E0;
$gray-lighter2: #f8f8f8;
$gray-light   : #D1D1D1;
$gray-medium  : #B3B3B3;
$gray-dark    : #717073;
$black        : #000000;
$green        : #ADA400;
$green2       : #848005;
$gray-lighter2: #F0F0F0;



//Fonts
$fontfamily1 : "HelveticaNeueLT Std",  Arial, sans-serif;

//breakpoints
$sm : 48em; //768px
$md : 64em; //1024px
$lg : 75em; //1200px
$xlg: 90em; //1440px

//_mediaqueries.scss
$_fg_small:   $sm; // up to "tablet" size
$_fg_medium:  $md; // up to "desktop" size
$_fg_large:   $lg; // up to bigger desktop size
$_fg_xlarge:  $xlg; // up to bigger desktop size

//_grid.scss
$flexboxgrid-grid-columns: 12; 
$flexboxgrid-gutter-width: 25px; // Set the gutter between columns.
$flexboxgrid-outer-margin: 25px; // Set a margin for the container sides.
$flexboxgrid-outer-margin-mobile: 30px;
// Create or remove breakpoints for your project
// Syntax:
// name SIZErem,
$flexboxgrid-breakpoints:
  sm $sm 46rem, // 768
  md $md 63rem, // 1024
  lg $lg 71rem; //1200
$flexboxgrid-max-width: 100%;
