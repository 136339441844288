.search-result {
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid $gray-light;
  &__title {
    @include subheadline_2;
    margin-bottom: 0;
  }
  &__type{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
    text-transform: capitalize;
  }
  &__content {
    margin-top: 25px;
    font-size: 14px;
  }
}
.search-results {

  &__counter {
    display: block;
    font-size: 12px;
    margin-bottom: 80px;
    padding: 5px 0;
  }
}
