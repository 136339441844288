.page {
  &__title {
    @include headline_1;
    margin-bottom: 75px;
  }
  &__subtitle {
    @include subheadline_1;
  }
}

// SQUARE
.square {
  background: $white;
  min-height: 100px;
  display: flex;
  position: relative;
  margin-bottom: 5px;
  transition: all .4s ease-in-out;
  @include r(medium) {
    padding-bottom: 100%;
    overflow: hidden;
    margin-bottom: 30px;
  }
  &:hover {
    background-color: $gray-lighter2;  
  }
  &__inner {
    padding: 20px;
    display: flex;
    flex-direction: column;
    @include r(medium) {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }
  &__title {
    @include link_2;
    margin-bottom: 5px;
    transition: all .4s ease-in-out;
  }
  &__subtitle {
    margin-bottom: 5px;
    transition: all .4s ease-in-out;
  }
  &__link {
    @include link_5;
    margin-top: auto;
    color: $green;

    svg {
      stroke: $gray-dark;
    }
  }
  a:hover {
    :not(.square__link) {
      color: $gray-dark;
    }
  }
}

// CTA ITEM
.cta-item {
  background: $white;
  display: flex;
  align-items: center;
  height: 50px;
  &__marker {
    display: flex;
    flex: 0 0 50px;
    align-content: center;
    justify-content: center;
    svg {
      width: 8px;
    }
  }
  &__text {
    display: block;
    flex: 1;
    font-weight: bold;
  }
  &__arrow {
    display: flex;
    flex: 0 0 50px;
    align-content: center;
    justify-content: center;
    svg {
      width: 20px;
      stroke: $gray-dark;
    }
  }
}

// LIST
.list {
  &__title {
    @include subheadline_5;
    color: $gray-medium;
    margin-bottom: 12px;
  }
  &__item {
    margin-bottom: 2px;
    &-link {
      @include subheadline_5;
      font-size: 14px;
    }
    &-text {
      margin-bottom: 0;
    }
  }
}

.bullets {
  list-style: disc;
  li {
    margin-left: 20px;
  }
}

// DIVIDER
.divider {
  margin-bottom: 30px;
  padding-bottom: 30px;

  &--thick {
    border-bottom: 4px solid $white;
  }
  
  // DIVIDER SECONDARY
  &--thin {
    border-bottom: 1px solid $gray-medium;
  }
}

.side-links {
  display: flex;
  justify-content: flex-end;
}

// Tile 
.letter {
  @include subheadline_1;
  margin: 0 0 25px;
  border-bottom: 4px solid $white;
  display: flex;
  padding-bottom: 15px;
  margin: 30px 0 0 0;
}
.tile {
  border-top: 1px solid $gray-medium;
  &__inner {
    padding: 20px 0;
    @include r(large) {
      padding: 40px 0;
      &--fixed {
        height: 220px;
        overflow: hidden;
      }
    }
  }

  &__title {
    @include subheadline_1;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.letter-group {
  // padding-top: 60px;
  // margin-top: -60px;
}
.glossary {
  position: fixed;
  top: 0;
  right: 5px;
  display: flex;
  justify-content: flex-end;
  transition: all .3s ease-in-out;
  transform: translate3d(0, 100px, 0);
  @include r(medium) {
    position: sticky;
  }
  &--fixed {
    position: sticky;
    transform: translate3d(0, 80px, 0);
  }
  &__letters {
    // position: absolute;
    top: 30px;
    right: 5px;
    @include r(medium) {
      right: 20px;
    }
  }
  &__letter {
    color: $gray-medium;
    font-size: 14px;
    text-align: center;
    &:hover {
      cursor: pointer;
      color: $green;
    }
    &--active {
      color: $black;
    }
  }
  &__title {
    margin-bottom: 0;
  }
  
}


.box {
  background: $white;
  padding: 30px;
}

.item {
  border-bottom: 1px solid $gray-medium;
  padding: 20px 0;
  position: relative;
  padding-top: 80px;
  margin-top: -60px;

  &__title {
    @include subheadline_1;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__industry {
    display: block;
  }

  &__content {
    margin-top: 10px;
  }
}



// -------------------
//  Page Navigation
//  Next / Prev - Back to section links
// -------------------
.pagination {
  display: flex;
  border-top: 4px solid $white;
  padding-bottom: 15px;
  padding-top: 15px;
  font-size: 14px;
  &--prev,
  &--next {
    text-decoration: none;
    font-weight: bold;
    flex: 1;
    a {
      text-decoration: none;
    }
    svg {
      width: 20px;
      height: 14px;
      position: relative;
      top: 1px;
      stroke: $gray-dark;
      transition: all .4s ease-in-out;
    }
    &:hover {
      svg {
        stroke: $green;
      }
    }
  }
  &--next {
    text-align: right;
  }
  &--back {
    font-weight: bold;
  }

  .screen-reader-text {
    display: none;
  }
}

.nav-links {
  display: flex;
}

.page-numbers {
  font-weight: bold;
  color: $green;
  padding: 0 5px;
  &.current {
    color: $black;
  }
}
