[v-cloak] {
  display: none;
}


.navigation {
  // margin-top: 25px;
}
.main-content {
  padding-top: 150px;
  transition: all .4s ease-in-out;
  backface-visibility: hidden;
  @include r(medium ) {
    padding-top: 0;
  }
}
.content-secondary {
  // display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
}

.content-primary {
  margin-top: 25px;
  margin-bottom: 25px;

  @include r(medium) {
    margin-top: 200px;
    margin-bottom: 50px;
  }
  .home & {
    margin-top: 25px;
  }
}