/* ----------------------------------------------------------------------------
 * Responsive
 * Usage

    @include r($md)  { }
    @include r(1024px)  { }

 * ------------------------------------------------------------------------- */

// Override as required.
$_fg_small:  768px !default; // up to "tablet" size
$_fg_medium: 1024px !default; // up to "desktop" size
$_fg_large:  1280px !default; // up to bigger desktop size
$_fg_xlarge: 1440px !default; // up to even bigger desktop size

@mixin r($_fg_media) {
  $small: $_fg_small;
  $medium: $_fg_medium;
  $large: $_fg_large;
  $xlarge: $_fg_xlarge;
  
  @if unitless($_fg_small) {
    $small: $_fg_small * 1px;
  }
  @if unitless($_fg_medium) {
    $medium: $_fg_medium * 1px;
  }
  @if unitless($_fg_large) {
    $large: $_fg_large * 1px;
  }
  @if unitless($_fg_large) {
    $xlarge: $_fg_xlarge * 1px;
  }

  @if $_fg_media == small {
    @media only screen and (min-width: $small) { @content; }
  }
  @else if $_fg_media == medium {
    @media only screen and (min-width: $medium) { @content; }
  }
  @else if $_fg_media == large {
    @media only screen and (min-width: $large) { @content; }
  }
  @else if $_fg_media == xlarge {
    @media only screen and (min-width: $xlarge) { @content; }
  }

  @else if type-of($_fg_media) == number {
    @if unitless($_fg_media) {
      $_fg_media: $_fg_media * 1px;
    }
    @media only screen and (min-width: $_fg_media) { @content; }
  }
}