.widget {
  border-top: 4px solid $white;
  margin-top: 30px;
  padding-top: 30px;

  &__title {
    @include subheadline_1;
  }
  &__item {
    border-bottom: 1px solid $gray-medium;
    padding-bottom: 25px;
    margin-bottom: 25px;
    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  &__list {
    list-style: none;
    margin: 0 0 0px;
    padding: 0;
  }
}

.quick-links {
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    background: $white;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    height: 45px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    
    .icon {
      position: absolute;
      right: 20px;
      display: flex;
      top: 50%;
      transform: translateY(-50%);
      svg {
        width: 20px;
        height: 20px;
        stroke: $gray-dark;
      }
    }
  }
}

.links {
  margin-top: 5px;
  display: flex;
  &:empty {
    display: none;
  }
}
.link {  
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  margin-right: 15px;
  transition: all .4s ease-in-out;
  white-space: nowrap;
  .icon {
    margin-right: 5px;
    // width: 20px;
    svg {
      fill: $white;
      transition: all .4s ease-in-out;
    }
  }
  &:hover {
    color: $green;
    .icon {
      svg {
        fill: $green;
      }
    }
  }
}
.icon-marker  {
  .icon svg {
    width: 8px;
  }
}
.icon-plate  {
  .icon svg {
    width: 18px;
  }
}


.specials {

}





.event {
  border-top: 4px solid $white;
  padding-top: 30px;
  margin-top: 30px;
  &__image {
    margin-bottom: 10px;
  }
  &__date {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &__name {
    @include subheadline_1;
    margin-bottom: 15px;
  }
  &__meta {

  }
  .button {
    margin-top: 20px;
  }
}


.team {
  &__group {
    margin-bottom: 30px;
    border-bottom: 1px solid $gray-medium;
  }
  &__item {
   
  } 
  &__department {
    
    @include subheadline_1;
    margin-bottom: 20px;
  }
  &__name {
    @include subheadline_2;
  }
  &__position {
    display: block;
    @include label;
  }
  &__image {
    width: 100%;
    height: auto;
  }

  &__group:empty {
    border: 1px solid red;
    content: 'No results';
  }
}
.contact {
  &__group {
    margin-bottom: 30px;
    border-bottom: 1px solid $gray-medium;
  }
  &__item {

  }
  &__department {
    @include subheadline_1;
    margin-bottom: 20px;
  }
  &__name {
    @include subheadline_2;
  }
  &__position {
    display: block;
    @include label;
  }
}
