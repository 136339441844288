
// -------------------
//  Filters
// -------------------

.filters {
  background: $white;
  display: flex;
  height: 60px;
  position: relative;
  z-index: 1;
  @include r(medium) {
    position: sticky;
    top: 0;
  }
  &--fixed {
    position: fixed;
    z-index: 1;
    top: 0;
    width: calc(50% - 37px);
  }
  &__trigger {
    &--fixed {
      height: 90px;
    }
  }
  &__item {
    // min-width: 200px;
    height: 60px;
    display: flex;
    padding: 0 30px 0 25px;
    align-items: center;
    position: relative;
    transition: all .4s ease-in-out;
    margin-right: 40px;
    font-size: 14px;

    @include r(medium) {
      font-size: 16px;
    }

    svg {
      fill: $gray-dark;
      width: 10px;
      height: 7px;
      position: relative;
      // top: -2px;
      margin-right: 5px;
      transition: all .4s ease-in-out;
    }
    &:hover,
    &:active,
    &:focus,
     {
      background: $green;
      color: $white;
      cursor: pointer;

      .filters__item-list {
        opacity: 1;
        pointer-events: all;
      }
      svg {
        fill: $white;
      }
    }
    &-label {
      font-weight: bold;
      height: 100%;
      flex: 1;
      align-items: center;
      display: flex;
    }
    &-list {
      position: absolute;
      left: 0;
      top: 60px;
      z-index: 10;
      width: 320px;
      margin: 0;
      padding: 0;
      list-style: none;
      color: $white;
      background: $green;
      opacity: 0;
      transition: all .4s ease-in-out;
      pointer-events: none;
      overflow: auto;
      max-height: 240px;
      @include r(medium) {
        width: 320px;
      }
    }
    &-option {
      color: $white;
      transition: all .4s ease-in-out;
      padding: 12px 25px;
      &:hover {
        background: $green2;
        cursor: pointer;
      }
    }
    .vdp-datepicker {
      position: absolute;
      left: 0;
      top: 60px;
    }

  }
  
  &__reset {
    margin-left: auto;
    margin-right: 0;
    height: 60px;
    display: none;
    align-items: center;
    padding: 0 20px;
    @include r(medium) {
      display: flex;
    }
    svg {
      width: 10px;
      height: 10px;
      transition: all .4s ease-in-out;
    }
    &:hover {
      cursor: pointer;

      svg {
        transform: rotate(180deg);
      }
    }
  }
}


// -------------------
//  Events List  
// -------------------
.events-list {

  &__inner {

    margin: auto;
    @include r(large) {
      padding: 0 0;
    }
  }

  &__filters {
  
  }

  &__header {
    @include subheadline_5;
    font-weight: bold;
    margin: 0 0 25px;
    border-bottom: 4px solid $white;
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;
    height: 80px;

   > span {
      display: flex;
      align-items: center;
      flex: 1 1;
    }

    &-pages {
      margin-left: auto;
      margin-right: 0;
      @include body-copy;
    }
  }

  &__paging {
    @include r(medium) {
      margin-left: auto;
      margin-right: 0;;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    height: 100px;
  }

  &__actions {
    display: flex;
    justify-content: center;
  }

  &__item {
    position: relative;
    margin-bottom: 25px;

    border-bottom: 4px solid $white;
    padding-bottom: 25px;

    &-image {
      display: block;
      margin-bottom: 20px;
      img {
        display: block;
      }
    }
    &-info {
      position: relative;
    }

    &-date {
      display: block;
      @include label;
      @include r(medium) {
        margin-bottom: 10px;
      }
    }
    &-title {
      @include subheadline_1;
      display: block;
      text-decoration: none;
      margin-bottom: 10px;
    }
    &-meta {
      margin: 0;
    }
    &-hour {

    }
    &-venue {

    }

    &-button {
      display: none;
      @include r(medium) {
        display: inline-flex;
        // position: absolute;
        // right: 0;
        // top: 35px;
        // display: inline-flex;
      }
    }

  }


}


// -------------------
//  Listings List  
// -------------------
.listings-list {
 
  &__header {

    font-weight: bold;
    margin: 0 0 25px;
    border-bottom: 4px solid $white;
    display: flex;
    align-items: flex-end;
    padding-bottom: 5px;
    height: 80px;

   > span {
      display: flex;
      align-items: center;
      flex: 1 1;
    }

    &-pages {
      margin-left: auto;
      margin-right: 0;

    }
  }

  &__paging {
    @include r(medium) {
      margin-left: auto;
      margin-right: 0;;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    height: 100px;
  }

  &__actions {
    display: flex;
    justify-content: center;
  }

  &__item {
    position: relative;
    margin-bottom: 25px;
    border-bottom: 4px solid $white;
    padding-bottom: 25px;
    
    @include r(medium) {
      display: flex;
      align-items: center;
    }

    &:first-child {

    }

    &:last-child {
      margin-bottom: 0;
    }

    &-info {

    }
    &-complex {
      display: block;
      text-transform: uppercase;
      // margin-bottom: 5px;
    }
    &-type {
      font-size: 12px;
    }
    &-title {

      display: block;
      text-decoration: none;
      margin-top: 0;
      margin-bottom: 5px;
    }
    
    &-meta {
      margin-bottom: 20px;
      @include r(medium) {
        margin-bottom: 0;
      }
    }
 
    &-button {
      @include r(medium) {
        margin-right: 0;
        margin-left: auto;
      }
    }

  }
}
