// img {
//   width: 100%;
//   height: auto;
// }


.b-lazy__loading {
  background: url('img/loading.svg') center center no-repeat;
  background-color: $gray-light;
  transition: all .4s ease-in-out;
  .b-lazy {
    // transform: scale(1.1);
  }
}

.b-lazy {
  width: 100%;
  height: auto;
	padding: 0;
	box-sizing: border-box;
  transition: all .5s ease-in-out;
  // transform: scale(1.05);
  will-change: transform;
  opacity: 0;
}
.b-loaded {
  opacity: 1;
  transform: scale(1);
  display: block;
  &.b-active {
    transform: scale(1);
    @include r(medium) {
      &:hover {
        // transform: scale(1.1);
      }
    }
  }
}

.swiper-lazy {
  // transform: scale(1.05);
  transition: all .5s ease-in-out;
  will-change: transform;
  opacity: 0;
  @include r(medium) {
    &:hover {
      // transform: scale(1.1);
    }
  }
  &-loaded {
    opacity: 1;
    transform: scale(1);
  }
}

.img-ratio {
  &--regular {
    display: block;
    height: 0;
    overflow: hidden;
    padding-bottom: 65.91%;
  }
  &--fullwidth {
    display: block;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.21%;
  }
  &--square {
    display: block;
    height: 0;
    overflow: hidden;
    padding-bottom: 100%;
  }
}

.img--hover {
  &:hover {
    transform: scale(0.975);
  }
}

.image-fpo {
  display: block;
  padding-bottom: 56.21%;
  background: $gray-medium;
  position: relative;
  &:after {
    content: "FPO";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    font-size: 50px;
    font-weight: bold;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
