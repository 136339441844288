.utilities {
  position: fixed;
  z-index: 100;
  right: 0;
  left: auto;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;

  &__link {
    background: $white;
    color: $gray-dark;
    height: 50px;

    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    padding-right: 20px;
    padding-left: 20px;

    line-height: 50px;
  }
}


.post-edit-link {
  display: none;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: $green;
  color: $white;

  font-size: 12px;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: $white;
  }

  @include r(medium) {
    display: block
  }
}
