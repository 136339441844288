@mixin button {
  font-weight: bold;
  text-decoration: none;
  display: inline-flex;
  transition: all .4s ease-in-out;
  justify-content: center;
  align-items: center;

  background: $green;
  color: $white;
  font-size: 14px;
  // min-width: 160px;
  height: 40px;
  padding: 0 25px;
  white-space: nowrap;

  border: 0;
  position: relative;

  @include r(medium) {
    // min-width: 160px;
  }
  svg {
    display: block;
    margin-right: 10px;
    fill:  $white;
    transition: all .4s ease-in-out
  }

  &:hover {
    background: $green2;
    color: $white;
    cursor: pointer;

    svg {
      fill: $white;
    }
  }

  &__icon {
    &--loading {
      position: absolute;
      right: 10px;
      svg {
        margin-right: 0;
      }
    }
  }


  &--small-long {
    background: $white;
    color: $gray-dark;
    font-size: 12px;
    height: 32px;
    @include r(medium) {
      min-width: 138px;
    }
    &:hover {
      background: $gray-lighter2;
      color: $gray-dark;
    }
  }
  &--small-short {
    background: $white;
    color: $gray-dark;
    font-size: 12px;
    height: 32px;
    @include r(medium) {
      // min-width: 138px;
    }
    &:hover {
      background: $gray-lighter2;
      color: $gray-dark;
    }
  }
  &--large {
    min-width: 200px;
    height: 45px;
    background: $green;
    color: $white;
    @include r(medium) {
      min-width: 230px;
      height: 50px;
      font-size: 16px;
    }
    &:hover {
      background: $gray-dark;
      color: $white;
    }
  }
  &--icon {
    justify-content: flex-start;
    padding-left: 20px;
    svg {
      margin-right: 10px;
    }
    span {
      margin-left: auto;
    }
  }
}

.button {
  @include button;
}
