.fade-enter-active {
  transition: all .3s 0s ease-in-out;
}

.fade-leave-active {
  transition: all .3s 0 ease-in-out;
  @include r(medium) {
    transition: all .3s 0s ease-in-out;
  }
}

.fade-enter  {
  @include r(medium) {
    opacity: 0;
  }
  //transform: translateY(-10px); //-20px
}

.fade-leave-to  {
  @include r(medium) {
    opacity: 0;
  }
  //transform: translateY(10px);
}

.fade-enter-to  {
  opacity: 1;
}
