.swiper {
  position: relative;
  overflow: hidden;
  &__buttons {
    display: flex;
    margin-top: 40px;
  }
  &__button--next,
  &__button--prev {
    width: 36px;
    height: 35px;
    z-index: 10;
    cursor: pointer;
    transition: all .4s ease-in-out;
    svg {
      stroke: $white;
      transition: all .4s ease-in-out;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      svg {
        stroke: $gray-dark;
      }
    }
    &.swiper-button-disabled {
      opacity: .5;
      pointer-events: none;
      cursor: default;
    }
  }
  &__button--prev {
    margin-right: 15px;
  }
}
