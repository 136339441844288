.footer {
  background: $gray-dark;
  padding-top: 50px;
  padding-bottom: 25px;
  @include r($md) {
    position: relative;
    z-index: 1;
  }
  &__logo {
    display: block;
    margin-bottom: 30px;
    svg {
      width: 54px;
      height: 33px;
      fill: $white;
    }
  }
  &__title {
    color: $white;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
  &__links {
    margin: 0 0 40px;
    padding: 0;
    list-style: none;
  }
  &__link {
    font-size: 14px;
    color: $white;
    text-decoration: none;
  }  
  &__newsletter {
    &-title {
      color: $white;
      font-size: 24px;
      line-height: 1.16;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  &__copyright {
    font-size: 14px;
    margin: 0;
    color: $white;
  }
  &__privacy {
    font-size: 14px;
    margin: 0;
    a {
      color: $white;
      text-decoration: none;
    }
    
    @include r(medium) {
      text-align: right;
    }
  }
}


.form-subscribe {
  margin-bottom: 25px;
  max-width: 320px;
  color: $white;
  
  &__title {
    color: $white;
    font-size: 13px;
    font-weight: bold;
    margin: 0 0 10px;
  }
  &__input {
    border: 0;
    padding: 12px 40px 12px 15px;
    font-size: 16px;
    width: 100%;
    border-radius: 0;
    background-image: url(img/icon-email.svg);
    background-repeat: no-repeat;
    background-size: 22px 13px;
    background-position: calc(100% - 15px) 50%;
    &::placeholder {
      color: #B3B3B3;
    }
    &:focus {
      outline: none;
    }
  }
}


.social {
  display: flex;
  &__link {
    align-self: flex-end; // width: 30px;
    // height: 30px;
    margin-right: 20px;
    svg {
      fill: $white;
      transition: all .4s ease-in-out;
    }
    &:hover {
      svg {
        fill: $green;
      }
    }
  }
}