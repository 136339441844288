$buttonGreen : $green;
$linkGreen : $green;
$titleGray : $gray-medium;
$lightGray : $gray-light;
$mobile : $_fg_medium;
$break768: $_fg_medium;
$tablet: $_fg_medium;

@mixin media($media) {
  @media only screen and (max-width: $media) { @content; }
}
//////////////



.gfield_radio {
  li {
    float: left;
    margin: 5px;
    display: block;
  }
}

.gform_wrapper .woocommerce-billing-fields {
  margin: 0 !important;

  input[type=text] {
    font-size: 14px !important;
  }

  #input_1_23_3 {
    width: 75px !important;
  }


  #input_1_31_3 {
    @extend #input_1_23_3;
  }

  .gform_body {

    label.gfield_label {
      font-weight: normal;
    }

    .gsection_title {
      @extend label.gfield_label;
      font-size: 1em !important;
    }
  }

  #field_1_14 {
    margin-top: 15px;
  }

  ul li.gfield {
    margin-bottom: 30px;
  }

  ul li.textform {
    margin-bottom: 10px !important;
  }

  input[type=text] {
    border: 1px solid $gray-medium;
    font-size: 15px;
  }

  input[type=tel] {
    border: 1px solid $gray-medium;
    font-size: 15px;
  }

  textarea {
    border: 1px solid #e5e5e5 !important;
    font-size: 15px !important;
  }
}

div#gform_wrapper_1 {
  border: none !important;
}

.gform_wrapper {
	background: $white !important;
	padding: 30px 30px !important;
	
	@include media($mobile) {
		padding: 10px !important;
	}
	
	
  h1,h2,h3,h4,p, label {
    font-weight: 300 !important;
  }

  input.medium {
    color: $titleGray;
    font-weight: 300;
  }

  input[type=tel] {
    border: 1px solid $gray-medium;
    font-size: 15px;
  }

  ul {
    padding-left: 0 !important;
    
    &.gform_fields {
	    li {
		    background-image: none;
		    padding-left: 0;
		    
		    @include media($mobile) {
		    	max-width: 100%;
		    }
	    }
    }
    
  }

  h2.gsection_title {
    font-size: 1.6rem !important;
  }

  .gfield_checkbox li label {
    height: 30px;
    margin-right: 100px !important;
  }

  div#input_7_30_3  {
    width: 75px !important;
  }

  div#input_7_29_3{
    @extend div#input_7_30_3;
  }

  div#input_7_63 {
    @include media($mobile) {
      width: 74% !important;
    }
  }

  input#gform_submit_button_7 {
    @include media($mobile) {
      width: 221px;
    }
  }

  div#input_7_17 {
    @extend div#input_7_63;
  }
  
	input[value='gf_other_choice'] {
		display: none;
	  
		&::-webkit-input-placeholder {
			color: #ababab;
		}
		
		&:-moz-placeholder { /* Firefox 18- */
			color: #ababab;  
		}
		
		&::-moz-placeholder {  /* Firefox 19+ */
			color: #ababab;  
		}
		
		&:-ms-input-placeholder {  
			color: #ababab;  
		}
	}
  
  input[value='Other'] {
	  margin-left: 0 !important;
	  color: #717171;
	  font-size: 14px;
  }
  
	.dd-container {
		font-size: 14px;
		width: 99% !important;
		.dd-select {
			background-color: $gray-lighter2 !important;
			color: $titleGray;
			border-radius: 0;
			.dd-selected {
				padding: 2px 0px 2px 10px !important;
				color: $titleGray;
			}
		}
		.dd-option {
			font-size: 14px;
		}
	}
}

.form_content, #customer_details {
  background: $white;
  padding: 30px 30px;
  border: 1px solid $buttonGreen;

  
  h3 {
	  margin-top: 0;
	  margin-bottom: 15px;
	  margin-left: 3px;
  }
  
  p {
	  
	  &#billing_country_field {
		 padding-bottom: 0;
		 margin-bottom: 0; 
	  }
  }
  
  .input-text {
	  padding: 8px 20px;
  }
  
  .chosen-container {
	  
	  .chosen-single {
		  padding-left: 20px;
	  }
	  
	  .chosen-results {
		  
		  li {
			  padding: 5px 20px;
		  }
	  }
  }
  
}

.gfield_checkbox {
  @extend .gfield_radio;
}

.gform_wrapper {
	input[type=radio],
	input[type=checkbox] {
		opacity: 0;
		display: none !important;
		float: left;
		width: 18px;
	}

	input[type=text], input[type=tel], input[type=email] {
		height: 33px !important;
		font-size: 14px !important;

		@include media($mobile) {
			height: 35px !important;
		}
	}

	input[type=radio] + label {
		cursor: pointer;
		background: url('img/unchecked_square.png') left center no-repeat;
		margin: 0 !important;
		clear: none;
		display: block;
		padding: 6px 8px 6px 40px !important;
		font-size: 14px;
		//margin-right: 100px !important;
	}




	input[type=checkbox] + label {
		cursor: pointer;
		background: url('img/unchecked_square.png') left center no-repeat;
		margin: 0 5px 0 2px !important;
		clear: none;
		display: block;
		padding: 5px 0 4px 40px !important;
	}

	.lt-ie9 {
		input[type=radio] {
			margin: 3px 4px 0;
		}
		input[type=radio] + label {
			padding: 0 !important;
			background: none;
			margin-right: 10px;
		}
		
		input[type=checkbox] {
			margin: 3px 4px 0;
		}
		input[type=checkbox] + label {
			padding: 0 !important;
			background: none;
			margin-right: 10px;
		}
	}
}

.product-addon {
	input[type=checkbox] {
		width: 30px;
		margin-right: 10px;
		opacity: 1;
		//visibility: hidden;
		&:before {
			content: '';
			cursor: pointer;
			background: url('img/unchecked_square.png') left center no-repeat;
			clear: none;
			display: block;
			height: 30px;
			width: 30px;
			float: left;
			visibility: visible;
		}
	}
	input[type=checkbox]:checked {
		&:before {
			content: '';
			cursor: pointer;
			background: url('img/checked_square.png') left center no-repeat;
			clear: none;
			display: block;
			height: 30px;
			width: 30px;
			float: left;
			visibility: visible;
		}
	}
}
 
.gform_wrapper {
  .top_label .gfield_label {
    font-weight: 300 !important;
  }
}

.grey_dropdown {
  width: 300px;
}

.gform_wrapper {
	
	input[type=radio]:checked + label {
		background-image: url('img/checked_square.png');
	}

	input[type=checkbox]:checked + label {
		background-image: url('img/checked_square.png');
	}

	input[type="submit"] {
		@include button;
	}

	::-webkit-input-placeholder {
		font-size: 14px !important;

	}

	:-moz-placeholder { /* Firefox 18- */
		font-size: 14px !important;
	}

	::-moz-placeholder {  /* Firefox 19+ */
		font-size: 14px !important;
	}

	:-ms-input-placeholder {
		font-size: 14px !important;
	}

	input[type=text], textarea, input[type=email], input[type=phone], input[type=tel] {
		background: $gray-lighter2;
		border: 1px solid $gray-medium;
	}

}


.cart {
	
	.coupon {
		width: 100%;
	}
	
	input {	
		
		&.button {
			//@include span-columns(1.68);
			color: $white;
			font-weight: 300;
			background: $buttonGreen;
			border: none;
			border-radius: 0;
			width: inherit;
			float: left !important;
			width: 17% !important;
			text-shadow: none;
			
			@include media($mobile) {
				width: 100% !important;
			}
			
			&:hover {
				background: $linkGreen;
			}
		}
		
		&.checkout-button {
			
			&.button {
				//@include span-columns(3.18);
				background: $buttonGreen;
				color: $white;
				border: none;
				width: 30% !important;
				margin-top: 20px;
				float: left;
				border-radius: 0;
				padding: 10px 35px;
				text-shadow: none;
				
				&:hover {
					background: $linkGreen;
					color: $white;
				}
				
				@include media($mobile) {
					width: 100% !important;
				}
			}
		}
		
		&#coupon_code {
			//@include span-columns(1.44);
			width: 12% !important;
			margin-right: 8px;
			margin-top: 5px;
			display: inline-block;
			
			@include media($mobile) {
				width: 100% !important;
			}
		}
	}
	
	input[name*="update_cart"] {
		display: none;
	}	
}

.cart_totals {
	
	h2 {
		
		@include media($break768) {
			text-align: right;
		}
	}
	
	table {
		
		@include media($break768) {
			text-align: right;
		}
		
		th {
			width: 100% !important;
			font-weight: 300;
		}
	}
}



.contact_form {
  background-color: $white;
  padding: 20px;
}

.dd-container {
	width: 100% !important;

	.dd-select {
		height: 30px;
		.dd-selected {
			
			@include media($tablet) {
				//padding-left: 3px !important;
			}
		}
	}
}

ul.dd-options.dd-click-off-close {
  padding: 0 !important;
}

.woocommerce-billing-fields, .woocommerce-shipping-fields {
  h3 {
    font-weight: 400;
  }
  .form-row {
    label {
      font-weight: 300;
    }

    input[type=text], textarea {
      background: $gray-lighter2 !important;
      border: 1px solid #e5e5e5;
    }
  }
}

.woocommerce-page {
	
	.checkout {
		
		.col2-set {
			background: $white;
			padding: 30px;
			
			.dd-select {
				height: 33px;
				
				.dd-selected {
					//padding-top: 4px;
					padding: 0 0 0 20px !important;
				}
			}
		}
	}
	
	#payment  {
		background: $lightGray;
		
		label {
			display: block !important;
		}
		
		ul {
			
			&.payment_methods {
				background: $white;
				border-bottom: none;
			}
		}
	  
		fieldset {
			border: none;
			padding-left: 0;
			margin-left: 0;
		}

		.form-row-last {
			width: 100%;
		}
		  
		.form-row {
		  	margin-bottom: 0;
		  	
		  	&.form-row-first {
		  		
		  		label {
					float: none;
				}
			}
		    
		  input[type=submit] {
		    border: none;
		    background: $buttonGreen;
		    border-radius: 0;
		    text-shadow: none;
		    color: $white;
		
		    &:hover {
		      background-color: $linkGreen;
		    }
		  }
		}
	
	    li label[for='payment_method_authorize_net_cim'] img:nth-child(n+4) {
	      margin-right: 10px;
	    }
	
	    li label[for="payment_method_authorize_net_cim_echeck"] img:nth-child(n+1) {
	      margin-right: 10px;
	    }
	    
	    #place_order {
		    float: left;
	    }
	    
	    div.payment_box {
		    background: none;
		    box-shadow: none;
		    padding-left: 0;
		    
		    p {
			    
			    &.form-row {
					margin-bottom: 10px !important;
			    }
			    
			    a {
				    background: $buttonGreen;
				    color: $white;
				    padding: 10px 35px;
				    font-weight: 300;
				    border: none;
				    border-radius: 0;
				    text-shadow: none;
				    
				    &:hover {
				      background-color: $linkGreen;
				    }
			    }

                label {
                  display: block !important;
                  clear: both;
                }
		    }
		    
		    .form-row {
			    padding-left: 0;
		    }
	    }
  	}
  	
  	table {
	  	
	  	&.cart {
		  	
		  	th {
			  	font-weight: 300;
		  	}
	  	}
	  	
	  	&.shop_table {
		  	background: $white;
		  	border-collapse: collapse !important;
			border-radius: 0;

			th {
				font-weight: 300 !important;
			}
			
			td {
		  	
			  	&.product-name {
				  	
				  	dl {
					  	
					  	&.variation {
						  	
						  	dt {
							  	font-weight: 300;
						  	}
					  	}
				  	}
			  	}
			 
	  		}
	  		
	  		tfoot {
		  		
		  		td {
			  		font-weight: 300;
		  		}
	  		}
  		}
  	}
  	.woocommerce-message {
	  	background: $white;
	  	border: 1px solid $buttonGreen;
	  	border-radius: 0;
	  	box-shadow: none;
	  	
	  	a {
		  	color: $white;
		  	background: $buttonGreen;
		  	font-weight: 300;
		  	padding: 10px 35px;
		  	border: none;
		  	border-radius: 0;
		  	text-shadow: none;
		  	
		  	&:hover {
				background: $linkGreen;
			}
	  	}
	  	
	  	&:before {
		  	background: none;
		  	color: $buttonGreen;
		  	box-shadow: none;
	  	}
  	}
  	
  	.woocommerce-error {
	  	border: 1px solid $buttonGreen !important;
	    background: $white;
	    border-radius: 0;
	    box-shadow: none;
	    text-shadow: none;
	    
	    &:before {
		  	background: none;
		  	color: $buttonGreen;
		  	box-shadow: none;
	  	}
    
	  	li {
		  	
		  	strong {
			  	color: #ff0000;
			  	font-weight: bold;
		  	}
	  	}
  	}
  	
  	form {
	  	
	  	.form-row {
		  	
		  	&.create-account {
			  	
			  	label {
				  	
				  	&.checkbox {
					  	
					  	display: block
				  	}
			  	}
		  	}
	  	}
  	}
}

.woocommerce .woocommerce-info, .woocommerce-page .woocommerce-info {
    border: 1px solid $buttonGreen !important;
    background: $white;
    border-radius: 0;
    box-shadow: none;

    &:before {
      content: none;
    }
  }
.woocommerce #payment ul.payment_methods li img, .woocommerce-page #payment ul.payment_methods li img {
  float: left;
  padding-left: 9px;
  padding-top: 7px;

}

div#billing_country_chosen, div#shipping_country_chosen {
  display: none;
}

.woocommerce form .form-row label, .woocommerce-page form .form-row label {
  font-weight: 300;
}

.woocommerce-checkout .form-row .chosen-container {
  display: none;
}

#payment {
  label {
    display: inline-block !important;

    img {
      float: left;
      padding-left: 8px;
    }

    img:nth-child(n+4) {
      margin-right: 10px;
    }
  }

  label[for="payment_method_authorize_net_cim_echeck"] img:nth-child(n+1) {
      margin-right: 10px;

  }
}

li#field_7_61 {
  margin-bottom: 15px;
}

.wrapper .gform_wrapper li.gfield div.ginput_container ul.gfield_checkbox li, .wrapper .gform_wrapper li.gfield div.ginput_container ul.gfield_radio li {
	
	@include media($mobile) {
		@include span-columns(12);
	}
}

.activities_container .gform_wrapper span.gform_description {
	clear: both;
	display: block;
}

.gform_wrapper {
	input[type="submit"] {
		&.gform_button {
			background: $buttonGreen;
			border: 0;
			color: $white;
			text-shadow: none;
			font-weight: normal;
			padding: 10px 35px;
			border-radius: 0;
			box-shadow: none;
			
			&:hover {
				background: #8B9414;
				border: 0;
				color: $white;
				text-shadow: none;
				font-weight: normal;
				padding: 10px 35px;
				border-radius: 0;
				box-shadow: none;
			}
		}
	}
	
	.gfield_checkbox {
		@include media($mobile) {
			li {
				width: 100%;
				label {
					width: 100%;
					height: auto;
				}
			}
		}
	}
	
	.gfield_full {
		width: 100%;
	}
}

.woocommerce {
	form {
		.form-row {
			padding: 0;
		}
		
		input[type="text"] {
			padding-left: 5px;
		}
		
		input[type="email"] {
			padding-left: 5px;
		}
		
		input[type="password"] {
			padding-left: 5px;
		}
		
		input[type="submit"] {
			background: $buttonGreen;
			border: 0;
			color: $white;
			text-shadow: none;
			font-weight: normal;
			padding: 10px 35px;
			border-radius: 0;
			box-shadow: none;
			
			&:hover {
				background: #8B9414;
				border: 0;
				color: $white;
				text-shadow: none;
				font-weight: normal;
				padding: 10px 35px;
				border-radius: 0;
				box-shadow: none;
			}
		}
	}
	
	.cart-collaterals {
		.cart_totals {
			.wc-proceed-to-checkout {
				text-align: right;
				a {
					display: inline-block;
					background: $buttonGreen;
				}
			}
		}
	}
}

#gform_wrapper_8 {
	
	@include media($mobile) {
		.gfield {
			width: 100%;
		}
	}
	#input_8_7 {
		li {
			width: 100%;
			height: auto;
			
			label {
				width: 100%;
				height: auto;
			}
		}
	}
	
	#gform_submit_button_8 {
		@include media($mobile) {
			width: 100%;
		}
		
	}
}

.lost_reset_password {
	
}


//overwrites
body {

	.gform_wrapper li.gfield ul.gfield_checkbox li, 
	.gform_wrapper li.gfield ul.gfield_radio li {
		margin-bottom: 10px !important;
		vertical-align: text-top !important;
	}
	.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])
	.gform_wrapper select {
		-webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
		border: 1px solid $gray-medium;
    border-radius: 0;
    outline: none;
		background: transparent;
		color: $gray-medium;
		transition: all .4s ease-in-out;
		&::placeholder {
      color: $gray-medium;
      transition: all .4s ease-in-out;
    }
    &:focus {
      color: $gray-dark;
      &::placeholder {
        color: $gray-dark;
      }
    }
	}

	.gform_wrapper ul.gform_fields li {
		@include media($lg) {
			width: 100%;
		}
	}

	.gform_wrapper select {
		appearance: none;
		border: 1px solid $gray-medium;
		border-radius: 0;
		outline: none;
		background-color: $gray-lighter2;
		background-image: url(img/icon-arrow-down.svg);
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: 10px 10px;
		background-position: calc(100% - 10px) 50%;
		padding-top: 4px !important;
		padding-bottom: 3px;
		margin-bottom: 0;
	}
}


