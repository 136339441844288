/**!
  Theme Name: BishopRanch Tenants
  Theme URI: https://tenants.bishopranch.com
  Description: BishopRanch Tenants Theme
  Version: 1.0
  Author: Allis Studio
  Author URI: http://allisinc.com
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Tags: allisinc
  Text Domain: allis
*/

//variables and utilities
@import 'variables';
@import 'mixins/mediaqueries';

//vendors 
@import 'vendors/reset';
@import 'vendors/normalize';
@import 'vendors/swiper';
@import 'vendors/bourbon/bourbon';
@import 'vendors/neat/neat';

//base styles
@import 'base/base';
@import 'base/typography';

//block components
@import 'components/utilities';

@import 'components/shortcodes';
@import 'components/buttons';
@import 'components/vue-datepicker';
// @import 'components/forms';
// @import 'components/gravityforms/gravity';
// @import 'components/gravity-forms';
@import 'components/forms-original';
@import 'components/grid';
@import 'components/icons';
@import 'components/images';
@import 'components/swiper';
@import 'components/listings';
@import 'components/transitions';
@import 'components/widgets';
@import 'components/search-form';

//layout
@import 'layout/layout';
@import 'layout/page';
@import 'layout/header';
@import 'layout/sidebars';
@import 'layout/footer';


//pages
@import 'pages/search';
