
.sidebar {
  // position: relative;
  @include r(medium) {
    top: 0;
    position: sticky;
  }
  
  &__nav {
    display: none;
    @include r($md) {
      height: 100vh;
      display: flex;
      align-content: center;
      flex-direction: column;
    }
    nav {
      margin: auto 0;
    }
  }

  &__map {
    background: $green;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    color: $white;
    font-weight: bold;
    text-decoration: none;

    @include r($md) {
      position: absolute;
      bottom: 30px;
      left: 0;
    }
  }
}



.menu {
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    color: $gray-dark;
    font-weight: bold;
    text-decoration: none;   
    &--current {
      color: $green;
    }
  }
  &:hover {
    .menu__item {
      color: rgba($gray-dark, .25);
      &:hover {
        color: $green;
      }
    }
  }
}


.sub-menu {
  margin: 0 0 20px;
  padding: 0;
  width: 100%;
  min-height: min-content;
  
  @include r(medium) {
    margin: 50px 0;
    padding: initial;
    display: flex;
    align-items: center;
  }

  &__name {
    display: flex;
    color: rgba($gray-dark, .3);
    font-weight: bold;
    font-size: 20px;
    @include r(medium) {
      display: none;
    }
  }
  &__icon {
    margin-left: auto;
    svg {
      fill: rgba($gray-dark, .3);
      width: 14px;
      height: 8px;
    }
  }

  ul {
    margin: 20px 0;
    padding: 0;
    list-style: none;
    @include r(medium) {
      margin: 0;
      display: block;
      flex: 1;
    }
  }
  &__item {
    display: block;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.023em;
    margin-bottom: 0.44em;
    color: $gray-dark;
    text-decoration: none;
    @include r(medium) {
      font-size: 6vw;
      line-height: 0.91;
      color: $white;
    }
    @include r(xlarge) {
      font-size: 90px;
    }
  }

  li:last-child a {
    margin-bottom: 0;
  }


  &--active {
    .sub-menu__name {
      color: $green;
    }
    .sub-menu__icon svg {
      fill: $green;
    }
  }
}



.content__inner {
  position: relative;
  flex: 1;
  &--border {
    @include r(medium) {
      &:before, &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        background: $gray-medium;
      }
      &:before {
        left: -$flexboxgrid-gutter-width / 2;
      }
      &:after {
        right: -$flexboxgrid-gutter-width / 2;
      }
    }
  }
}
