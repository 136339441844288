.br-calendar.vdp-datepicker__calendar {
  background: $green;
  border: 0;
  padding: 20px;

  header {
    span {
      @include body-copy;
      color: $white;
      height: 36px;
      line-height: 36px;
      
    }
    .prev:not(.disabled):hover, 
    .next:not(.disabled):hover, 
    .up:not(.disabled):hover {
      background: $green2;
    }
    .prev {
      &:after {
        border-right-color: $white;
      }
    }
    .next {
      &:after {
        border-left-color: $white;
      }
    }
  }
  .cell {
    @include label;
    color: $white;
    height: 36px;
    line-height: 36px;
    border: 0;
    transition: all .4s ease-in-out;
    
    &.day-header {
      text-transform: uppercase;
    }

    &.selected {
      background: $black;
    }

    &:not(.blank):not(.disabled).day:hover, 
    &:not(.blank):not(.disabled).month:hover, 
    &:not(.blank):not(.disabled).year:hover {
      background: $green2;
      border: 0;
    }
  }
}

.filterStartDate {
  display: none;
}

