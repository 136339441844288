html, body {
  margin: 0;
  height: auto;
  min-height: 100%;
  overscroll-behavior: none;
}

body {
  background: $gray-lighter;
  font-family: $fontfamily1;
  line-height: 1.375;
  color: $gray-dark;
  overflow-x: hidden;
}

*,
*:after,
*:before {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}


a:focus, 
button {
  outline: none;
}


p:empty,
strong:empty {
  display: none;
}

@include r(medium) {
  a[href^="tel:"] {
    background-image: none;
    cursor: default;
  }

  a[href^="tel:"]:hover {
    color: $gray-dark;
  }
}
