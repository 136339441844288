.logo {
  display: block;
  text-decoration: none;
  position: relative;
  z-index: 1;
  width: 207px;
  height: 19px;

  @include r($md) {
    position: absolute;
    top: 30px;
  }

  &__image {
    width: 100%;
    height: auto;

    svg {
      fill: $gray-dark;
      width: 207px;
      height: 19px;
    }
  }

  &__name {
    color: $gray-dark;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.015em;
    margin-top: 5px;
  }
}

.header {
  padding: 30px 25px 20px;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  background: $gray-lighter;

  @include r(medium) {
    position: absolute;
    display: none;
  }

  &__logo {
    flex: 0 1 207px;
  }

  &__nav {
    background: $green;
    width: 47px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    flex: 0 0 47px;
    svg {
      fill: $white;
    }
  }

  &__search {
    height: 47px;
    width: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    flex: 0 0 47px;
    svg {
      width: 19px;
      height: 19px;
      fill: $white;
    }
  }
}




//applied on body; 
.overlay-nav--on {
  overflow: hidden;

  .overlay-nav {
    visibility: visible;
    opacity: 1;
  }
  .main-content {
    transform: translate3d(-10%, 0, 0);
    @include r(medium) {
      transform: none;
    }
  }
}

.overlay-nav {
  background: rgba($gray-dark, .5);
  position: fixed;
  z-index: 101;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: $white;
  overflow: auto;
  visibility: hidden;
  transition: all .4s ease-in-out;
  opacity: 0;

  @include r(medium) {
    background: $gray-dark;
  }

  &__close {
    position: absolute;
    z-index: 1;
    top: 30px;
    right: 25px;
    width: 47px;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    // box-shadow: 0 0 0 1px $white;
    cursor: pointer;
    
    @include r(medium) {
      top: 30px;
      right: 35px;
    }
    svg {
      width: 19px;
      height: 19px;
      fill: $gray-dark;
      @include r(medium) {
        fill: $white;
        width: 25px;
        height: 25px;
      }
    }

    @include r(medium) {}
  }

  &__search {
    height: 47px;
    width: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    top: 30px;
    right: 70px;
    svg {
      width: 19px;
      height: 19px;
      fill: $gray-dark;
      @include r(medium) {
      }
    }
  }


  &__content {
    position: relative;
    height: 100%;

    @include r(medium) {
      background: none;
    }

    .row {
      height: 100%;
    }

    .logo {
      &__name {
        color: $white;
      }

      svg {
        fill: $white;
      }
    }


    .menu {
      &__item {
        color: $white;
      }

      &:hover {
        .menu__item {
          color: rgba($white, .25);

          &:hover {
            color: $green;
          }
        }
      }
    }
  }


  &__sub-nav {
    height: 100vh;
    width: 80vw;
    overflow: auto;
    background: $white;
    padding: 25px;
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 100px;
    transition:  all .4s ease-in-out;
    backface-visibility: hidden;
    transform: translate3d(100%, 0, 0);

    @include r(medium) {
      display: flex;
      width: auto;
      // align-items: center;
      align-items: stretch;
      background: none;
      padding: 0;
      position: static;
      transform: translate3d(0, 0, 0);
    }

    .overlay-nav--on & {
      transform: translate3d(0, 0, 0);
    }
  }



}
